import axios from 'axios'
class Request {
	instance // axios实例
	interceptors // 拦截器

	constructor(config) {
		this.instance = axios.create(config)
		this.interceptors = config.interceptors
		// 实例特有的拦截器
		this.instance.interceptors.request.use(
			this.interceptors?.requestInterceptor,
			this.interceptors?.requestInterceptorsCatch
		)

		this.instance.interceptors.response.use(
			this.interceptors?.responseInterceptor,
			this.interceptors?.responseInterceptorsCatch
		)

		// 全局通用的拦截器,每一个实例都有
		this.instance.interceptors.request.use(
			(config) => {
				return config
			},
			(err) => {
				return err
			}
		)
		this.instance.interceptors.response.use(
			(res) => {
				return res.data
			},
			(err) => {
				return err
			}
		)
	}

	request(config) {
		return new Promise((resolve, reject) => {
			// 单个请求对请求config的处理
			if (config.interceptors?.requestInterceptor) {
				config = config.interceptors.requestInterceptor(config)
			}

			this.instance
				.request(config)
				.then((res) => {
					if (config.interceptors?.responseInterceptor) {
						res = config.interceptors.responseInterceptor(res)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
					return err
				})
		})
	}

	get(config) {
		return this.request({ ...config, method: 'GET' })
	}
	post(config) {
		return this.request({ ...config, method: 'POST' })
	}
	delete(config) {
		return this.request({ ...config, method: 'DELETE' })
	}
	patch(config) {
		return this.request({ ...config, method: 'PATCH' })
	}
}

export default Request
