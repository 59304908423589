// 神策埋点
import sensors from 'sa-sdk-javascript'

export function sensorsMonitor() {
	const isDev = window.location.host === 'localhost:4001'
	sensors.init({
		server_url: isDev ? 'https://salasa.xcreditech.com/sa' : 'https://salasa.xcreditech.com/sa?project=production',
		heatmap: {
			// 是否开启点击图，default 表示开启，
			// 自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
			clickmap: 'default',
			// 是否开启触达注意力图，not_collect 表示关闭，
			// 不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
			scroll_notice_map: 'default',
			track_attr: ['data-sa', 'data-sensors'] // 全埋点自定义属性
		},
		is_track_single_page: true, // 是否单页面
		show_log: false,
		app_js_bridge: true,
		batch_send: true,
		is_track_device_id: true // 收集神策is_track_device_id
	})

	sensors.registerPage({
		current_url: window.location.href,
		appName: 'tifi'
	})
	sensors.quick('autoTrack')
	return sensors
}
