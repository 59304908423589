import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		token: sessionStorage.getItem('appId') ? sessionStorage.getItem('appId') : '',
		status: '1',
		scrollMap: {
			step01: 0, // 流程介绍
			step02: 0, // 关于我们
			step03: 0, // 贷款计算器
			step04: 0 // 底部表格
		},
		isH5: true,
		currentWidth: 0
	},
	mutations: {
		SetToken(state, token) {
			state.token = token
		},
		SetStatus(state, status) {
			state.status = status
		},
		SetScrollMap(state, { name, top }) {
			state.scrollMap[name] = top
		},
		SetIsH5(state, isH5) {
			state.isH5 = isH5
		},
		SetCurrentWidth(state, currentWidth) {
			state.currentWidth = currentWidth
		}
	},
	getters: {
		GetToken(state) {
			return state.token
		},
		GetStatus(state) {
			return state.status
		},
		GetScrollMAP(state) {
			return state.scrollMap
		},
		GetIsH5(state) {
			return state.isH5
		}
	}
})
export default store
