import Vue from 'vue'
import Router from 'vue-router'
import { removeScript } from '@/utils'
import { sensorsMonitor } from '@/plugins/sa'
import store from '@/store'
const sasdk = sensorsMonitor()

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch((error) => error)
}
let routes = []
if (CLIENT[3] === '--kenya') {
	routes = [
		{
			path: '/',
			component: (resolve) => require(['@/view/Kenya/index.vue'], resolve),
			redirect: { name: 'Home' },
			children: [
				{
					path: '/home',
					name: 'Home',
					component: (resolve) => require(['@/view/Kenya/Home/index.vue'], resolve)
				},
				{
					path: '/employer',
					name: 'Employer',
					component: (resolve) => require(['@/view/Kenya/Employer/index.vue'], resolve)
				},
				{
					path: '/employee',
					name: 'Employee',
					component: (resolve) => require(['@/view/Kenya/Employee/index.vue'], resolve)
				},
				{
					path: '/faq',
					name: 'FAQ',
					component: (resolve) => require(['@/view/Kenya/FAQ/index.vue'], resolve)
				},
				{
					path: '/help',
					name: 'Help',
					component: (resolve) => require(['@/view/Kenya/Help/index.vue'], resolve)
				},
				{
					path: '/about-us',
					name: 'AboutUs',
					component: (resolve) => require(['@/view/Kenya/AboutUs/index.vue'], resolve)
				},
				{
					path: '/terms-of-use',
					name: 'TermsOfUse',
					component: (resolve) => require(['@/view/Kenya/TermsOfUse/index.vue'], resolve)
				},
				{
					path: '/privacy-policy',
					name: 'PrivacyPolicy',
					component: (resolve) => require(['@/view/Kenya/PrivacyPolicy/index.vue'], resolve)
				}
			]
		}
	]
} else if (CLIENT[3] === '--mexico') {
	routes = [
		{
			path: '/',
			name: '/',
			component: (resolve) => require(['@/view/Mexico/index'], resolve),
			redirect: { name: 'Home' },
			children: [
				{
					path: '/home',
					name: 'Home',
					component: (resolve) => require(['@/view/Mexico/Home/index.vue'], resolve)
				},
				{
					path: '/condiciones', // 使用条款
					name: 'Condiciones',
					component: (resolve) => require(['@/view/Mexico/Condiciones/index.vue'], resolve)
				},
				{
					path: '/preguntas', // 常见问题
					name: 'Preguntas',
					component: (resolve) => require(['@/view/Mexico/Preguntas/index.vue'], resolve)
				},
				{
					path: '/avisoprivacidad', // 隐私协议
					name: 'Avisoprivacidad',
					component: (resolve) => require(['@/view/Mexico/Avisoprivacidad/index.vue'], resolve)
				}
			]
		}
	]
}

Vue.use(Router)
const router = new Router({
	mode: 'history',
	fallback: false,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.path === '/home' && to.query.step) {
			const y = store.state.scrollMap[to.query.step] || 0
			return { y }
		} else if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

router.beforeEach((to, from, next) => {
	if (to.path !== '/home') {
		removeScript('//js.hsforms.net/forms/v2-legacy.js')
		removeScript('//js.hsforms.net/forms/v2.js')
	}

	// 记录下当前扫码的pv
	const source = (to.query.source || '').toLowerCase()
	if ((to.path === '/' || to.path === '/home') && source === 'qrcode') {
		sasdk.track('tifi_qrcode2website_pageview')
	}

	if (to.matched.length === 0) {
		//如果未匹配到路由
		next('/home')
	} else {
		// document.title = to.meta.title
		next()
	}
})

export default router
