const baseSize = 32 // 32
const hasMax = true
// 设置 rem 函数
function fontRem() {
	// 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
	const scale = document.documentElement.clientWidth / 750
	let fontSize = baseSize * Math.min(scale, 2)
	if (hasMax && fontSize > 32) {
		// 设置了最大字体
		fontSize = 32
	}
	// 设置页面根节点字体大小
	document.documentElement.style.fontSize = fontSize + 'px'
}
// 初始化
fontRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
	fontRem()
}
