import qs from 'qs'
import { $https } from '../index'

const sample = {
	postForm(data, params) {
		return $https.get({
			url: `/niuma1?${qs.stringify(data)}`,
			params,
			transformRequest: [
				function (data) {
					let ret = ''
					for (let it in data) {
						ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
					}
					return ret
				}
			],
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
	}
}

export default sample
