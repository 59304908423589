import Request from './request'
import { BASE_URL, TIME_OUT, SESSION_ID, VERSION } from './request/config'

// 可以创建多个axios实例,拥有不同的拦截器
const $https = new Request({
	baseURL: BASE_URL,
	timeout: TIME_OUT,
	headers: {
		common: {
			sessionId: SESSION_ID,
			version: VERSION
		}
	},
	interceptors: {
		requestInterceptor: (config) => {
			return config
		},
		requestInterceptorsCatch: (err) => {
			return Promise.reject(err)
		},
		responseInterceptor: (config) => {
			// 响应成功关闭loading
			// let code = Number(data.data.code)
			// switch (code) {
			//   //约定code 409 token 过期
			//   case 409:
			//     if (!isRefreshing) {
			//       isRefreshing = true
			//       //调用刷新token的接口
			//       return refreshToken({ refreshToken: localStorage.getItem('refreshToken'), token: getToken() }).then(res => {
			//         const { token } = res.data
			//         // 替换token
			//         setToken(token)
			//         response.headers.Authorization = `${token}`
			//          // token 刷新后将数组的方法重新执行
			//         requests.forEach((cb) => cb(token))
			//         requests = [] // 重新请求完清空
			//         return service(response.config)
			//       }).catch(err => {
			//       //跳到登录页
			//         removeToken()
			//         router.push('/login')
			//         return Promise.reject(err)
			//       }).finally(() => {
			//         isRefreshing = false
			//       })
			//     } else {
			//       // 返回未执行 resolve 的 Promise
			//       return new Promise(resolve => {
			//         // 用函数形式将 resolve 存入，等待刷新后再执行
			//         requests.push(token => {
			//           response.headers.Authorization = `${token}`
			//           resolve(service(response.config))
			//         })
			//       })
			//     }
			//     return data
			//   case 40000006:
			//     // store.commit('SHOWTOAST', '登录超时，请重新登录！')
			//     setTimeout(() => {
			//       const param = { url: window.location.href }
			//       store.commit('goLogin', JSON.stringify(param))
			//     }, 2000)
			//     return data
			//   default:
			//     return data
			// }
			return config
		},
		responseInterceptorsCatch: (err) => {
			// if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
			//   router.push('/abnormal')
			// } else if (error.response.status < 510 && error.response.status >= 500) {
			//   router.push('/abnormal')
			// } else if (error.response.status === 404) {
			//   router.push('/apiNoFind')
			// } else {
			//   return Promise.reject(err)
			// }
			return Promise.reject(err)
		}
	}
})

export { $https }
