import Vue from 'vue'
import {
	Loading,
	Dialog,
	Carousel,
	CarouselItem,
	DropdownMenu,
	DropdownItem,
	Dropdown,
	TabPane,
	Tabs,
	Input,
	Autocomplete,
	Message
} from 'element-ui'
;[
	Loading,
	Dialog,
	Carousel,
	CarouselItem,
	DropdownMenu,
	DropdownItem,
	Dropdown,
	TabPane,
	Tabs,
	Input,
	Autocomplete
].forEach((_) => {
	Vue.use(_)
})

Vue.prototype.$message = Message
