import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n/i18n'
import './plugins'
import axios from '@/service/apis'

Vue.config.productionTip = false
Vue.prototype.baseUrl = 'https://static-hk.obs.ap-southeast-1.myhuaweicloud.com/web/tifiworld/img'
Vue.prototype.$axios = axios

new Vue({
	router,
	store,
	i18n,
	render: (h) => {
		return h(App)
	}
}).$mount('#app')
