<template>
	<div id="app">
		<transition>
			<router-view></router-view>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'App',
  methods: {
    getClientWidth() {
      const width = document.documentElement.clientWidth
      this.$store.commit('SetCurrentWidth', width)
      if(width < 1231) {
        this.$store.commit('SetIsH5', true)
      } else {
        this.$store.commit('SetIsH5', false)
      }
    }
  },
  mounted () {
    this.getClientWidth()
    window.addEventListener('resize', () => {
      this.getClientWidth()
    })
  },
}
</script>
<style>
@import url('./assets/css/index.css');
</style>
