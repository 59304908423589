import store from '@/store'

export const baseScroll = (el, name) => {
	// let top = 0
	// if (store.getters.GetIsH5) {
	//   top = el.getBoundingClientRect().top-10
	// } else {
	//   top = el.getBoundingClientRect().top-10
	// }
	const top = el.getBoundingClientRect().top - 50
	store.commit('SetScrollMap', { name, top })
}

export const goScrollToPage = (name) => {
	const top = store.state.scrollMap[name] || 0
	window.scrollTo({ top, behavior: 'smooth' })
}

export const arrToggle = (val, arr) => {
	let temp = new Set(arr)
	const isHas = temp.has(val)
	if (isHas) {
		const pos = arr.indexOf(val)
		arr.splice(pos, 1)
	} else {
		arr.push(val)
	}
	return { isHas, arr }
}
// 删除script标签
export const removeScript = (src) => {
	const scripts = document.getElementsByTagName('script')

	for (let i = 0; i < scripts.length; i++) {
		if (scripts[i] && scripts[i].src && scripts[i].src.includes(src)) {
			scripts[i].parentNode.removeChild(scripts[i])
		}
	}
}
