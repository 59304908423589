let BASE_URL = ''
let SESSION_ID = null
let VERSION = ''
const TIME_OUT = 50000

if (process.env.NODE_ENV === 'development') {
	BASE_URL = '/'
} else if (process.env.NODE_ENV === 'production') {
	BASE_URL = '/'
} else {
	BASE_URL = '/'
}

export { BASE_URL, TIME_OUT, SESSION_ID, VERSION }
